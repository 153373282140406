<template>
  <div class="newsContainer">
    <ComHeader :imgs="img" :titleConent="titleConent" class="ComHeader" />
    <div class="newslist">
      <van-tabs
        v-model="newsTypeId"
        title-active-color="rgba(103,245,255,1)"
        border
        @click="onClick"
      >
        <van-tab
          v-for="item in navcategroy"
          :key="item.id"
          :name="item.id"
          :title="item.newsTypeName"
        ><div v-if="newsDetail">
          <!-- <BSrcoll> -->
          <div class="newsdata" >
            <div
              v-for="item in NavList"
              :key="item.id"
              class="newsItem"
              @click="tonewsDetail(item)"
            >
              <div class="title">
                <p class="newtitle">{{ item.newsTitle }}</p>
                <p class="newsTime">
                  <span>{{ item.createTime }}</span>
                </p>
              </div>
              <div class="newImg">
                <img
                  :src="
                    item.newsImg && item.newsImg.length > 0
                      ? item.newsImg[0].url
                      : ''
                  "
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- </BSrcoll> -->
        <van-pagination
          v-model="pageIndex"
          :total-items="total"
          :items-per-page="pageSize"
          @change="changeIndex"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
        </van-pagination>
         </div>
           <!-- 详情页展示 -->
          <div class="newsDetails" v-else>
              <div class="headers">
                  <img :src="DetailContent.newsImg&&DetailContent.newsImg.length>0?DetailContent.newsImg[0].url:''" alt="">
                  <p>{{DetailContent.parentNewsTitle}}</p>
              </div>
              <van-divider content-position="left">新闻详情</van-divider>
            <div v-html="DetailContent.newsContent" class="rich"></div>
          </div>
        </van-tab>

      </van-tabs>
    </div>
    <FooterContainer />
  </div>
</template>
<script>
export default {
  name: "Mnews",
  data() {
    return {
      img: require("../../assets/backImg.jpg"),
      titleConent: {
        name: "新闻动态",
        ename: "news",
      },
      newsTypeId: "",
      navcategroy: [],
      pageIndex: 1,
      pageSize: 5,
      total: 0,
      NavList: [],
      newsDetail: true,
      DetailContent:{}
    };
  },
  created() {
    this.getNewscategroy();
  },
  methods: {
    //获取新闻列表数据
    async getnewsList() {
      let result = await this.$api.getnewsList(
        this.newsTypeId,
        this.pageIndex,
        this.pageSize
      );
      console.log(result);
      if (result.data.length > 0) {
        result.data.forEach((item) => {
          if (item.newsImg) item.newsImg = JSON.parse(item.newsImg);
        });
      }
      this.total = Number(result.page.totalCount);
      console.log(result.data, this.total);
      this.NavList = result.data;
    },
    //获取初始化数据
    async getNewscategroy() {
      let result = await this.$api.getnewscategroy();
      this.navcategroy = result.data;
      console.log(result.data);
      this.newsTypeId = result.data[0].id;

      this.getnewsList();
    },
    // //点击分页切换
    changeIndex(index) {
      this.pageIndex = index;
      this.getnewsList();
    },
    onClick(title, name) {
        if(!this.newsDetail){
            this.newsDetail=true
        }
      console.log(title, name);
      this.newsTypeId = title;
      this.pageIndex = 1;
      this.getnewsList();
    },
    //跳转到新闻详情页
    tonewsDetail(content) {
    //   console.log(content);
      this.DetailContent=content
      this.newsDetail=false
    },
  },
};
</script>
<style lang="scss" scoped>
.newsContainer {
  height: 100%;
}
.newslist {
  margin-bottom: 15px;
}
.newslist /deep/.van-tabs__line {
  background-color: rgba(103, 245, 255, 1);
}
.newsdata {
  padding: 10px 20px;
  min-height: 400px;
  .newsItem {
    width: 100%;
    height: 120px;
    box-sizing: border-box;
    display: flex;
    padding: 10px 0;
    align-items: center;
    border-bottom: 1px dotted rgb(240, 240, 240);
    .title {
      p {
        margin: 0;
        text-align: left;
        padding-left: 20px;
        box-sizing: border-box;
      }
      width: 60%;
      height: 60%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: space-between;
      justify-content: space-between;
      align-items: center;
      .newtitle {
        width: 100%;
        height: 70%;
        font-size: 16px;
      }
      .newsTime {
        width: 100%;
        height: 30%;
        font-size: 12px;
        line-height: 2;
      }
    }
    .newImg {
      flex: 1;
      img {
        width: 100%;
        height: 60px;
      }
    }
  }
}
.van-pagination {
  padding: 0 20px;
}
// 新闻详情页
.newsDetails{
       min-height:420px;
       padding:10px 20px;
       .headers{
           width:100%;
           img{
               width:80%;
               height:132px;
           }
           p{
               width:80%;
               margin:0 auto;
               font-size:13px;
               text-indent: 6px;
               line-height: 20px;
               text-align: left;
               color:#666666
           }
       }
       .rich /deep/ p {
        word-break: break-all;  //超出宽度截取
        max-width: 100% !important;
        font-size:15px;
      }
      .rich /deep/ img {
        display: block;
        margin: 0 auto;
        max-width: 90% !important;
      }
}
</style>